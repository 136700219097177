<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายการ สรุปฝาก-ถอนยูส
        </div>

        <div class="row mb-2">
          <div class="col-md-4">
            <div class="card-yellow">
              <div class="text-white">
                พันธมิตร : {{ zean_detail.fullname }}
              </div>
              <div
                class="mt-n50"
                style="color: #ffb400"
              >
                .
              </div>

              <button
                class="btn bg-white btn-block mt-1"
                @click="$router.push({ name: 'invite-friend' })"
              >
                <i class="fas fa-fast-backward mr-50" /> กลับหน้ารายการ
              </button>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card-yellow">
              <span class="text-white"> Commission </span>
              <div
                class="mt-n50"
                style="color: #ffb400"
              >
                .
              </div>

              <div class="row mt-1">
                <div class="col-6">
                  <button class="btn bg-white btn-block text-nowrap px-0 py-1"
                    @click="$router.push({ name: 'commission', params: { id: invite_code } })">
                    <i class="fas fa-id-card" /> รายงานคอมมิชชั่น
                  </button>
                </div>
                <div class="col-6">
                  <button class="btn bg-white btn-block text-nowrap px-0 py-1"
                    @click="$router.push({ name: 'commission', params: { id: invite_code } })">
                    <i class="fas fa-id-card " /> รายงานคอมมิชชั่น KR
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card-yellow">
              <span class="text-white"> ยูสภายใต้รวมทั้งหมด: {{ Commas(ref_total) }} คน </span>
              <div
                class="mt-n50"
                style="color: #ffb400"
              >
                .
              </div>

              <button
                class="btn bg-white btn-block mt-1"
                @click="$router.push({ name: 'reportmember_register', params: { id: invite_code } })"
              >
                <i class="fas fa-users mr-50" /> รายงานยูส
              </button>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก) :
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }"
            />
          </div>

          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง) :
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }"
            />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="GetList()"
          >
            ค้นหา
          </button>
        </div>

        <!-- <div class="mt-3 d-flex justify-content-between align-items-center">
          <div class="row no-gutters align-items-center">
            <div class="col-6" />
            <div class="col-6 d-flex justify-content-end">
              <input
                id="username"
                v-model="search_val"
                type="text"
                class="form-control"
                style="width: 150px;"
                placeholder="ค้นหา"
              >
            </div>
          </div>
        </div> -->
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            >
            {{
              scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
            }}
          </div>
        </template>
        <template #cell(deposit)="data">
          {{ Commas(data.item.deposit) }}
        </template>
        <template #cell(withdraw)="data">
          {{ Commas(data.item.withdraw) }}
        </template>
        <template #cell(bonus)="data">
          {{ Commas(data.item.bonus) }}
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BCardBody,
  BOverlay,
  BIconController,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
    BOverlay,
    BIconController,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      itemModal: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeChart: '3day',
      fields: [
        { key: '_id', label: 'ยูสเซอร์' },
        { key: 'deposit', label: 'ยอดฝาก', thClass: 'text-center' },
        { key: 'withdraw', label: 'ยอดถอน', thClass: 'text-center' },
        { key: 'bonus', label: 'ยอดโบนัส', thClass: 'text-center' },
      ],
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      invite_code: this.$route.params.id,
      zean_detail: { fullname: '' },
      ref_total: 0,
      search_val: '',
      show: false,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // this.totalRows = this.items.length
    this.GetZeanDetail()
    this.GetList()
  },
  methods: {
    async GetZeanDetail() {
      const obj = {
        invite_code: this.invite_code,
      }
      try {
        const { data } = await this.$http.post('/zean/detail', obj)
        // console.log(data)
        this.zean_detail = data.detail
        this.ref_total = data.ref_total
      } catch (e) {
        console.log(e)
      }
    },
    async GetList() {
      const obj = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
        page: this.currentPage,
        per_page: this.perPage,
        invite_code: this.invite_code,
      }
      this.show = true
      try {
        const { data } = await this.$http.post('/zean/depandwit', obj)
        // console.log(data)
        this.totalRows = data.total
        this.items = data.data
        this.show = false
      } catch (e) {
        console.log(e)
        this.show = false
      }
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}
.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
